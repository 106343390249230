var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-screen flex-1 mt-1 bg-gray-100 px-4 py-3 items-center"
  }, [_c('div', {
    staticClass: "bg-white w-full rounded-lg shadow pb-20"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6 mt-5"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_c('button', {
    staticClass: "rounded-full flex justify-center items-center w-8 h-8 mr-3",
    class: _vm.$colors.primary,
    on: {
      "click": function click($event) {
        return _vm.RouterBack();
      }
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("chevron_left")])]), _c('div', {
    staticClass: "text-xl font-bold text-green-700"
  }, [_vm._v(_vm._s(_vm.$t('expedition_details')))])])]), _c('div', {
    attrs: {
      "id": "detail"
    }
  }, [_c('div', {
    staticClass: "flex pr-5 pl-5 space-x-3"
  }, [_c('div', {
    staticClass: "flex justify-between shadow w-full h-auto p-4 my-1 rounded-lg border-gray-100"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_vm._m(0), _c('div', {
    staticClass: "ml-5 w-full space-y-2"
  }, [_c('div', {
    staticClass: "text-base mt-2 mb-2 font-semibold text-gray-700"
  }, [_vm._v(_vm._s(_vm.$t('detail')))]), _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('seller')) + " : "), _vm.formData.seller ? _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.seller.username) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('number_of_packages')) + " : "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.numberOfPackages) + " ")])]), _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('weight')) + " : "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.weight) + " ")])]), _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('from')) + " : "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.$f.getCountryNameByCode(_vm.options, _vm.formData.country)) + " ")])]), _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('to')) + " : "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.$f.getCountryNameByCode(_vm.options, _vm.formData.country_to)) + " ")])])])])]), _c('div', {
    staticClass: "flex justify-between shadow w-full h-auto p-4 my-1 rounded-lg border-gray-100"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_c('div', {
    staticClass: "ml-5 w-full"
  }, [_c('div', {
    staticClass: "text-sm text-gray-600 mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('expedition_date')) + " : "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formattedDateExpeded) + " ")])]), _vm.formattedDateReception != 'Invalid date' ? _c('div', {
    staticClass: "text-sm text-gray-600 mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('reception_date')) + " : "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formattedDateReception) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "text-sm text-gray-600 mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('transport_mode')) + " : "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.transportMode) + " ")])]), _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('transport_fees')) + " : "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_c('sup', [_vm._v(" " + _vm._s(_vm.currency))]), _vm._v(" " + _vm._s(_vm.formData.fees) + " ")])])])])])]), _c('div', {
    staticClass: "flex pr-5 pl-5 mt-6"
  }, [_c('div', {
    staticClass: "flex justify-between w-full h-auto p-4 rounded-lg border-gray-100"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_vm._m(1), _c('div', {
    staticClass: "ml-1 w-full p-2"
  }, [_c('div', {
    staticClass: "text-base font-semibold text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('products_expedition')) + " ")]), _c('div', {
    staticClass: "w-full mt-4"
  }, [_c('table', {
    staticClass: "min-w-full w-full"
  }, [_c('thead', {
    staticClass: "text-white"
  }, [_c('tr', {
    staticClass: "bg-green-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
  }, [_c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(_vm._s(_vm.$t('image')))]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(_vm._s(_vm.$t('name')))]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(_vm._s(_vm.$t('commodity_value')))]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(_vm._s(_vm.$t('quantity_sent')))]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(_vm._s(_vm.$t('quantity_received')))])])]), _c('tbody', {
    staticClass: "flex-1 sm:flex-none"
  }, _vm._l(_vm.formData.details, function (it) {
    return _c('tr', {
      key: it._id,
      staticClass: "flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
    }, [_c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2"
    }, [_c('div', [_c('img', {
      staticClass: "h-16 w-16 rounded-md mx-auto",
      attrs: {
        "alt": it.product.name,
        "src": it.product.picture || 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
      }
    })])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('b', [_vm._v(_vm._s(it.product.name))])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('b', [_vm._v(_vm._s(it.merchandise_value) + " "), _c('sup', [_vm._v(" " + _vm._s(_vm.currency))])])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_vm._v(" " + _vm._s(it.quantity.sent) + " ")]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('table', {
      staticClass: "min-w-full"
    }, [_c('thead', [_c('tr', [_c('th', {
      staticClass: "text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider"
    }, [_vm._v(_vm._s(_vm.$t('ok_good')))]), _c('th', {
      staticClass: "text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider"
    }, [_vm._v(_vm._s(_vm.$t('defective')))])])]), _c('tbody', {
      staticClass: "bg-white"
    }, [_c('tr', [_c('td', {
      staticClass: "px-6 py-4 border whitespace-no-wrap"
    }, [_vm._v(_vm._s(it.quantity.received))]), _c('td', {
      staticClass: "px-6 py-4 border whitespace-no-wrap"
    }, [_vm._v(" " + _vm._s(it.quantity.defective))])])])])])]);
  }), 0)])])])])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-100"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("list_alt")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-100"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("local_mall")])]);

}]

export { render, staticRenderFns }